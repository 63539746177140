import React, { Component } from 'react';
import * as firebase from 'firebase';


import './App.css';

import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/jsx';
import 'brace/theme/monokai';



import { Pane, Text, Heading, Button, CornerDialog, Portal } from 'evergreen-ui'


// var JSREPL =require('jsrepl/build/jsrepl');
class App extends Component {
  constructor() {
    super();
    // window.JSREPL = JSREPL;
    // var jsrepl = new JSREPL.JSREPL({  
    //   input: ()=>{},  
    //   output: ()=>{},  
    //   result: ()=>{},  
    //   error: ()=>{},  
    //   progress: ()=>{},  
    //   timeout: {  
    //     time: 30000,  
    //     callback: ()=>{}  
    //   }  
    // });  
    // jsrepl.loadLanguage('JavaScript', false, function () {  
    //   alert('javascript loaded');  
    // });
    
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyAZU8RmrHl6dgGUQWdG3MWaktqyeuhvP_s",
        authDomain: "osei-panopticon.firebaseapp.com",
        databaseURL: "https://osei-panopticon.firebaseio.com",
        projectId: "osei-panopticon",
        storageBucket: "osei-panopticon.appspot.com",
        messagingSenderId: "890624142458"
      });
    }
    this.db = firebase.firestore();
    this.db.settings({ timestampsInSnapshots: true });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
        this.connectedToDb();
      }
    });
  }

  state = {
    showMessage: false,
    message: null,
    user: null,
    result: '',
    code: ''
  }

  signIn = () => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      const provider = new firebase.auth.GithubAuthProvider();
      firebase.auth().signInWithPopup(provider).then(result => {
        var token = result.credential.accessToken;
        var user = result.user;
        this.setState({ user });
      }).catch(function (error) {
        console.log(error);
      });
    });
  }

  connectedToDb() {
    this.db.collection("message").doc(this.state.user.email).onSnapshot(doc => {
      this.setState({ showMessage: true, message: doc.data() });
    });

    this.db.collection("code").doc(this.state.user.email).get().then(doc => {
      const { code } = doc.data();
      this.setState({ code: code })
    })
  }

  run = () => {
    const result = eval(this.state.code);
    this.setState({ result: result })
  }

  onChange = newValue => {
    this.setState({
      code: newValue
    });
    const { email, displayName } = this.state.user;
    this.db.collection("code").doc(email).set({
      name: displayName,
      email,
      code: newValue
    });
  }

  render() {
    return (
      <Pane
        padding={12}
      >
        <Pane
          background="tint2"
          elevation={1}
          padding={8}
        >
          <Heading size={900} marginBottom={16}>Show Me Your Code</Heading>
          {this.state.user && <><AceEditor
            mode="jsx"
            theme="monokai"
            debounceChangePeriod={1000}
            onChange={this.onChange}
            value={this.state.code}
            showPrintMargin={false}
            name="cool"
            width="100%"
          />
            {/* <Button onClick={this.run} marginTop={8}>Run</Button> */}
            {/* {this.state.result} */}
          </>
          }
          {!this.state.user && <Button
            onClick={this.signIn}
            marginTop={8}
            appearance="default">
            Login
          </Button>}
        </Pane>
        <CornerDialog
          title="Messages from Osei"
          isShown={this.state.showMessage}
          hasCancel={false}
          confirmLabel="OK Osei"
          onCloseComplete={() => this.setState({ showMessage: false })}
        >
          {this.state.message && this.state.message.message && <Text>{this.state.message.message}</Text>}
          {this.state.message && this.state.message.code && <AceEditor
            mode="jsx"
            theme="monokai"
            value={this.state.message && this.state.message.code}
            maxLines={this.state.message.code.split('\n').length}
            readOnly
            showPrintMargin={false}
            name="cool"
            width="100%"
          />}
        </CornerDialog>
        <Portal>
          <Pane padding={24} position="fixed" bottom={0} right={0}>
            <Button onClick={() => this.setState({ showMessage: true })}>Show last message</Button>
          </Pane>
        </Portal>
      </Pane>
    );
  }
}

export default App;
